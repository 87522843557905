import logo from './logo.svg';
import { Route, Switch } from "react-router-dom";
import Home from "./home";
import Login from "./login";
import Logout from "./logout";
import Job from "./job";
import PageNotFound from "./pagenotfound";
import './App.css';


function App() {
    return (
        <>
          
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/job" component={Job} />
                <Route exact component={PageNotFound} />
            </Switch>
            
        </>
    )
}

export default App; 
