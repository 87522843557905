import axios from "axios";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import Header from "../common/header";
import Footer from "../common/footer";
import Progress from "./progress";
import Gallery from "./gallery";
import JobControls from "./controls";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function Job()
{
    const {id, page} = useParams();
    const history = useHistory();
    
    return (
        <section>
          <Header currentPage="Job" />
          <div className="bg-light rounded mt-3">
            <div className="container">
              <h1>In Progress: Toilet Run</h1>
              <Progress />
              <Gallery />              
              
            </div>
          </div> 
           <JobControls />
        </section>
      );

};