
import JobList from "../common/joblist";
import StartJob from "./startjob";
import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";

function Completed() {
           
    const MessageError = localStorage.getItem('MessageError');
    const MessageSuccess = localStorage.getItem('MessageSuccess');
    
    const [onError, setError] = useState(MessageError);
    const [onSuccess, setSuccess] = useState(MessageSuccess);

    // Formik for login form
    const loginFormik = useFormik({
        initialValues: {
            email: '',
            password: '',
            rememberMe: false
        },
        onSubmit: values => {
            const user = {
                email: values.email,
                password: values.password,
                rememberMe: values.rememberMe
            };

            
        },
        validate: values => {
            let errors = {}

            if (!values.username) {
                errors.email = 'You must enter a valid User Name';
            } 

            if (!values.password) {
                errors.password = 'You must enter a correct password';
            }

            return errors;
        }
    });


    return (

        <div className="section">
            <div className="row">

                <form onSubmit={loginFormik.handleSubmit}>
                    <div className="mb-4">
                        
                        <div className="input-group align-center mt-4">
                            Week Starting: <input type="date" name="date" />
                        </div>
                        {(loginFormik.errors.password && loginFormik.touched.password) ? <div className="text-danger">{loginFormik.errors.password}</div> : ''}

                    </div>
                    
                    <div className="mb-4 text-center">
                        <button type="submit" className="btn btn-primary">Start New Round</button>
                        <p></p>
                    </div>
                    
                </form>
            </div>

            <div className="section joblist">
                <div className="row job">
                    <h3>Toilet Round</h3>
                    <a className="btn btn-primary">View</a>
                    <span className="DateStart">28 September 2023</span>
                </div>
                <div className="row job">
                    <h3>St Hill Street Round</h3>
                    <a className="btn btn-primary">View</a>
                    <span className="DateStart">28 September 2023</span>
                </div>
                <div className="row job">
                    <h3>Paper Round</h3>
                    <a className="btn btn-primary">View</a>
                    <span className="DateStart">28 September 2023</span>
                </div>        
            </div>
            
        </div>  
       
    );
};

export default Completed;