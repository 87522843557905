
import JobList from "../common/joblist";
import StartJob from "./startjob";
import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";


function InProgress() {

    
    const MessageError = localStorage.getItem('MessageError');
    const MessageSuccess = localStorage.getItem('MessageSuccess');
    
    const [onError, setError] = useState(MessageError);
    const [onSuccess, setSuccess] = useState(MessageSuccess);

    // Formik for login form
    const loginFormik = useFormik({
        initialValues: {
            email: '',
            password: '',
            rememberMe: false
        },
        onSubmit: values => {
            const user = {
                email: values.email,
                password: values.password,
                rememberMe: values.rememberMe
            };

            
        },
        validate: values => {
            let errors = {}

            if (!values.username) {
                errors.email = 'You must enter a valid User Name';
            } 

            if (!values.password) {
                errors.password = 'You must enter a correct password';
            }

            return errors;
        }
    });

    return (
        <div className="section">
            
            <div className="section joblist">
                <div className="row job">
                    <h3>Toilet Round</h3>
                    <a className="btn btn-primary" href="/job">Resume</a>
                    <span className="DateStart">28 September 2023</span>
                </div>
                <div className="row job">
                    <h3>St Hill Street Round</h3>
                    <a className="btn btn-primary" href="/job">Resume</a>
                    <span className="DateStart">28 September 2023</span>
                </div>
                <div className="row job">
                    <h3>Paper Round</h3>
                    <a className="btn btn-primary" href="/job">Resume</a>
                    <span className="DateStart">28 September 2023</span>
                </div>        
            </div>
            
            <div className="section">
                <StartJob formik={loginFormik} onError={onError} onSuccess={onSuccess} />
            </div>
        </div>  
        );
};

export default InProgress;