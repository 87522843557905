import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import Header from "../common/header";
import LoginHelper from "./loginhelper";
import Footer from "../common/footer";

function Login() {

    const history = useHistory();

    /* ---------------- Global initialisation ---------------- */

    const userId = localStorage.getItem('id');
    const token = localStorage.getItem('token');
    const loginMessageError = localStorage.getItem('loginMessageError');
    const loginMessageSuccess = localStorage.getItem('loginMessageSuccess');

    /* ----------------------- useState Hooks ---------------------------- */

    // Login hooks
    const [loginError, setLoginError] = useState(loginMessageError);
    const [loginSuccess, setLoginSuccess] = useState(loginMessageSuccess);

    if (userId) {
        // If user id is set in login page that means the user has been logged out. Utilize this to remove user associated token in db.
        axios.delete('revokeToken/' + userId + '/' + token)
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.log(error.response.data.message);
            })
            .then(function () {
                localStorage.clear();
            });
    } else if (token) {
        // Clear local storage if token is set
        localStorage.clear();
    }

    /* ------------------------------ User define functions ---------------------------------------- */

    const loginUser = (user) => {
        const response = axios.post('login',  {
              "user": user.user,
              "pin":user.pin,
            
        }).then(function (response) {
            
            if (response.data.result == 'Failed')
            {
                alert("Failed to login: " + response.data.message);
            }
            else
            {
                console.log(response.data.result);
                localStorage.setItem('currentUser', response.data.user);
                history.push("/");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        
        
    }

   

    /* ----- useEffect Hooks ----- */

    // One time UI render
    useEffect(() => {
        if (localStorage.getItem('actingAs')) {
            localStorage.removeItem('actingAs');
        }

        if (localStorage.getItem('actingAsEmail')) {
            localStorage.removeItem('actingAsEmail');
        }

        if (localStorage.getItem('actingAsName')) {
            localStorage.removeItem('actingAsName');
        }
    }, []);

    /* -------------------------------- useFormik Hooks --------------------------------------- */

    // Formik for login form
    const loginFormik = useFormik({
        initialValues: {
            email: '',
            password: '',
            rememberMe: false
        },
        onSubmit: values => {
            const user = {
                user: values.username,
                pin: values.password
            };

            loginUser(user);
        },
        validate: values => {
            let errors = {}

            if (!values.username) {
                errors.email = 'You must enter a valid User Name';
            } 

            if (!values.password) {
                errors.password = 'You must enter a correct password';
            }

            return errors;
        }
    });

    /* ---------- Event handlers ---------- */


    return (
        <>
            <Header currentPage="Login" />
                <div className="container">
                    <h1>Login</h1>
                    <LoginHelper formik={loginFormik} loginError={loginError} loginSuccess={loginSuccess} />
                </div> 
            
        </>
    )
}

export default Login;