
const LoginHelper = ({ formik, loginError, loginSuccess }) => (
    <div className="bg-light p-5 rounded mt-3">
        <div className="container">
            <div className="row">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <div className="form-label">Please Login to proceed</div>
                        <div className="input-group">
                            <span className="input-group-text">Name</span>
                            <input className="form-control sm" type="text" name="username" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.username} />
                        </div>
                        {(formik.errors.email && formik.touched.email) ? <div className="text-danger">{formik.errors.email}</div> : ''}
                        <div className="input-group  mt-4">
                            <span className="input-group-text">Pin</span>
                            <input className="form-control sm" type="password" name="password" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} />
                        </div>
                        {(formik.errors.password && formik.touched.password) ? <div className="text-danger">{formik.errors.password}</div> : ''}
                    </div>
                    
                    <div className="mb-4 text-center">
                        <button type="submit" className="btn btn-primary px-5 mx-2">Login</button>
                        {/*<a className="btn btn-success px-5 mx-2" href="/">Login </a>*/}
                        <p></p>
                        <p>Forgot your user or pin?<br/> Contact your administrator</p>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
);

export default LoginHelper;