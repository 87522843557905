import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = (currentPage) => {
    <header>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container></Container>
        </Navbar>
    </header>
};

export default Header;