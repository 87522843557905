function Footer()  {
    let userName = "";
    if (localStorage.getItem('currentUser')) {
        userName = localStorage.getItem('currentUser');
    }

    return (
    <footer>
        <div className="footer-nav">
            <a href="/logout" class="btn btn-secondary">Logout</a>
        </div>
        <h3>Logged in as </h3>
        <p>{userName}</p>
    </footer>
    );
};

export default Footer;