function JobControls()  {

    return (
    <footer>
        <div className="footer-nav">
            <a href="/job/next" class="btn btn-success">Save & Next</a>
        </div>
        <a href="/" class="btn btn-danger">Save & Exit</a>
    </footer>
    );
};

export default JobControls;