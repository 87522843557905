
import { useState, useEffect, Component } from "react";

class GalleryItem extends Component
{
    
    constructor(props) {
        super(props);
        this.maxValue = 9;
        this.state = {isToggleOn: true, currentValue: 0};
        
        // This binding is necessary to make `this` work in the callback
        
    }

    
    IncreaseValue = () => {
        
        if ( this.state.currentValue == this.maxValue) { 
            this.setState({ currentValue: this.maxValue });
        }
        else
        {
            this.setState({ currentValue: this.state.currentValue + 1 });
        }
        
    }

    DecreaseValue = () => {
        
        if ( this.state.currentValue == 0) { 
            this.setState({ currentValue: 0 });
        }
        else
        {
            this.setState({ currentValue: this.state.currentValue - 1 });
        }
    }

    render() {
        
        return (     
                <div className="col gallery-item">
                    <img src="mini-jumbo.jpg"/>
                    <span>Mini-Jumbos</span>
                    <div className="controls">
                        <a className="btn btn-danger" onClick={this.DecreaseValue}>-</a>
                        <input type="text" className="sm" name="qty_" value={this.state.currentValue}/>
                        <a className="btn btn-success" onClick={this.IncreaseValue}>+</a>
                    </div>
                </div>
        );

    };
};

export default GalleryItem;