
import GalleryItem from "./gallery-item";

export default function Gallery()
{

    return (
        <section>
          <div className="bg-light p-5 rounded mt-3">
            <div className="container">

              <div className="row">
                <GalleryItem />
                <GalleryItem />
                <GalleryItem />
              </div>
              
              <div className="row">
                <GalleryItem />
                <GalleryItem />
                <GalleryItem />
              </div>

              <div className="row">
                <GalleryItem />
                <GalleryItem />
                <GalleryItem />
              </div>
              
              
            </div>
          </div> 
        </section>
      );

};