import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import Header from "../common/header";
import Footer from "../common/footer";

function Logout() {

    const history = useHistory();
    localStorage.removeItem("currentUser");
    history.push("/login");

    return (
        
        <>
            
        </>
    )
}

export default Logout;