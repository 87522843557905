
export default function Progress()
{

    return (
        <div className="container progress-container" style={{textAlign: "center",marginTop: "20px", paddingLeft: "7.5%"}}>
           
                {/*<progress className="dots" />*/}
                <div className="progress" style={{height: "10px", width: "90%"}}>
                    <div className="progress-bar" role="progressbar" style={{width: "15%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            
        </div>
    );
};