
const StartJob = ({ formik, onError, onSuccess }) => (
    <div className="bg-light p-5 rounded mt-3">
        <div className="container">
            <div className="row">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        
                        <div className="input-group  mt-4">
                            <select className="form-control" name="runtype" onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                <option value="St Hill Street Round">St Hill Street Round</option>
                                <option value="Toilet Round">Toilet Round</option>
                                <option value="Paper Round">Paper Round</option>
                            </select>
                        </div>
                        {(formik.errors.password && formik.touched.password) ? <div className="text-danger">{formik.errors.password}</div> : ''}
                    </div>
                    
                    <div className="mb-4 text-center">
                        <button type="submit" className="btn btn-primary">Start New Round</button>
                        <p></p>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
);

export default StartJob;