import axios from "axios";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import Header from "../common/header";
import Footer from "../common/footer";
import Nav from "./nav";
import Completed from "./completed";
import InProgress from "./inprogress";

export default function Home()
{


  
      
    // Checks for session validity
    const validateSession = async () => {
      let currentUser = localStorage.getItem('currentUser');
      
      //alert(currentUser);
      if (currentUser == null)
      {
        history.push("/login");
      }

      {/* try {

          const response = await axios.get('');


      } catch (error) {
          
      }*/}
    
    }
    

    const history = useHistory();

    useEffect(() => {
      
      validateSession();
    
    }, []);

    return (
        <section>
          <Header currentPage="Home" />
          <div className="bg-light p-5 rounded mt-3">
            <div className="container">
              <h1>Home</h1>
              <Tabs id="controlled-tabs-home">
                <Tab eventKey="inprogress" title="In Progress"><div class="container"><InProgress /></div></Tab>
                <Tab eventKey="completed" title="Completed"><div class="container"><Completed /></div></Tab>
                {/* <Tab eventKey="settings" title="Settings"><div class="container">Settings</div></Tab> */}
              </Tabs>
              
              
            </div>
          </div> 
          <Footer />
        </section>
      );

};